import { Tooltip, message } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import AttendanceExport from '../../attendanceExport/timeAttendance';
import {
  useGetAttendanceItemData,
  useUpdateStatus,
} from '../../../../api/attendanceHooks';
import { Spin } from 'antd';
import {
  AlertMessage,
  B,
  DefaultBody,
  Div,
  DropdownIcon,
  FooterComponent,
  H,
  PrimaryButton,
  SecondaryButton,
} from '../../../../components';
import AttendanceListViewTbl from '../../../../components/AttendanceListViewTbl';
import EmployeesSelector from '../../../../components/selector/EmployeesSelector';
import OvertimeNoPaySelector, {
  SelectorFilterTypes,
} from '../../../../components/selector/OvertimeNoPaySelector';
import { CheckBoxComp } from '../../../../components/v2/CheckBoxComp';
import theme from '../../../../theme';
import {
  IApproveModalData,
  IUserAttendanceSummary,
} from '../../../../types/attendance.types';
import { useWindowSize } from '../../../../util/screenSize';
import { tableSorter } from '../../../../util/tableSorter';
import {
  addDotsForLongText,
  capitalizeUnderscore,
  isHaveThreeDots,
  safeCheckNaN,
} from '../../../../util/utils';
import { StyledTableDiv } from '../../../people/PeopleTable';
import {
  Header,
  HeaderSection,
  LastColumn,
  LeftSection,
  MobileHideButton,
  RightSection,
} from '../home/TimeAttendance';
import ApproveModal from '../../shared/ApproveModal';
import { getApiError } from '../../../../util/getApiError';
import {
  minutesToHoursAndMinutes,
  monthToString,
} from '../../../../util/timeConvertion';
import {
  MenuItem,
  MenuStyle,
} from '../../../payroll/pages/payroll-home/PayRunTable';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';

type ParamAttendance = {
  id: string;
};
const View = () => {
  const { isDesktopLarge } = useWindowSize();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [dataSource, setDataSource] = useState<IUserAttendanceSummary[]>([]);
  const [modalData, setModalData] = useState<IApproveModalData>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [employees, setEmployees] = useState<number[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const params = useParams<ParamAttendance>();
  const [isChecked, setIsChecked] = useState(true);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const {
    mutate: getAttendanceList,
    data: attendanceData,
    isLoading: isLoadingAttendanceList,
    error: attendanceListError,
  } = useGetAttendanceItemData();

  const {
    mutate: updateStatus,
    isLoading: isLoadingUpdateStatus,
    error: updateStatusError,
    isSuccess: updateStatusSuccess,
  } = useUpdateStatus();

  useEffect(() => {
    if (updateStatusSuccess) {
      refetchAttendanceData();
      setIsApproved(true);
    }
  }, [updateStatusSuccess]);

  const refetchAttendanceData = async () => {
    await getAttendanceList(params.id);
  };

  useEffect(() => {
    getAttendanceList(params.id);

    setModalData({
      month: monthToString(attendanceData?.month),
      uploadedDate: dayjs(attendanceData?.createdAt).format('MMMM YYYY'),
      monthYear: `${monthToString(attendanceData?.month)} / ${
        attendanceData?.year
      }`,
      noOfEmployees: attendanceData?.employeeCount,
    });
  }, []);

  useEffect(() => {
    if (attendanceData && !attendanceListError)
      setDataSource(attendanceData.data);
    if (updateStatusError) message.error(getApiError(updateStatusError));

    if (attendanceData) {
      setModalData({
        month: monthToString(attendanceData?.month),
        uploadedDate: dayjs(attendanceData?.createdAt).format('MMMM YYYY'),
        monthYear: `${monthToString(attendanceData?.month)} / ${
          attendanceData?.year
        }`,
        noOfEmployees: attendanceData?.employeeCount,
      });
    }
  }, [attendanceData, updateStatusError]);
  useEffect(() => {
    if (dataSource && isChecked) {
      setSelectedRowKeys(
        dataSource
          .filter((data) => !data.hasErrors)
          .map((data) => data.employeeId),
      );
    } else {
      setSelectedRowKeys([]);
    }
  }, [dataSource, isChecked]);

  const handleApprove = async () => {
    await updateStatus({
      id: params.id,
      employeeIds: selectedRowKeys,
    });
    setIsOpen(false);
  };

  const columns: ColumnProps<IUserAttendanceSummary>[] = [
    {
      title: 'Emp Id',
      dataIndex: 'employeeId',
      width: 100,
      className: 'emp-id-column',
      render: (_value: string, data) => (
        <B type="b-small"> {data.employeeId ? data.employeeId : '-'}</B>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(
          safeCheckNaN(a.employeeId ? a.employeeId : '')
            ? a.employeeId
            : parseInt(a.employeeId as string),
          safeCheckNaN(b.employeeId ? b.employeeId : '')
            ? b.employeeId
            : parseInt(b.employeeId as string),
        ),
    },
    {
      title: 'Display Name',
      dataIndex: 'fullName',
      render: (_value: string, data) => (
        <Div display="flex">
          {isHaveThreeDots(data?.fullName, 25) ? (
            <B type="b-small">
              {data.fullName ? (
                <Tooltip placement="topLeft" title={data.fullName}>
                  {isDesktopLarge && data.fullName
                    ? addDotsForLongText(data?.fullName, 20)
                    : data.fullName
                    ? addDotsForLongText(data?.fullName, 15)
                    : '-'}
                </Tooltip>
              ) : (
                '-'
              )}
            </B>
          ) : (
            <B type="b-small" pr="8px">
              {data.fullName ? data.fullName : '-'}
            </B>
          )}
        </Div>
      ),
    },
    {
      title: 'OT Hours',
      dataIndex: 'totalOverTimeMinutes',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.totalOverTimeMinutes?.toString()
            ? minutesToHoursAndMinutes(data.totalOverTimeMinutes)
            : '-'}
        </B>
      ),
    },
    {
      title: 'No Pay Hours',
      dataIndex: 'totalNoPayMinutes',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.totalNoPayMinutes?.toString()
            ? minutesToHoursAndMinutes(data.totalNoPayMinutes)
            : '-'}
        </B>
      ),
    },
    {
      title: 'No of Attendance',
      dataIndex: 'totalWorkingDays',
      render: (_value: string, data) => (
        <B type="b-small">
          {data.totalWorkingDays ? data.totalWorkingDays : '-'}
        </B>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      key: uniqueId(),
      width: 100,
      render: (_value: string, data) => (
        <LastColumn>
          <Link
            to={`/time-attendance/view/${params.id}/${data.employeeId}`}
            className="view">
            {attendanceData?.status === 'APPROVED' ||
            attendanceData?.status === 'ARCHIVED' ? (
              <B type="b-default" color={theme.blue500}>
                View
              </B>
            ) : (
              <B type="b-default" color={theme.blue500}>
                Edit
              </B>
            )}
          </Link>
        </LastColumn>
      ),
    },
  ];

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const firstErrorData = dataSource?.find(
      (data) => data.hasErrors && data.errors,
    );
    if (firstErrorData) {
      setShowErrorMessage(true);
      const firstErrorValue = Object.values(firstErrorData.errors)[0];
      setErrorMessage(firstErrorValue);
    }
  }, [dataSource]);

  const handleFilter = (value: CheckboxValueType[]) => {
    let filteredData = attendanceData?.data;

    if (value?.length === 2) {
      filteredData = filteredData?.filter(
        (data) => data.totalOverTimeMinutes > 0 && data.totalNoPayMinutes > 0,
      );
    } else if (
      value?.includes(capitalizeUnderscore(SelectorFilterTypes.OVERTIME))
    ) {
      filteredData = filteredData?.filter(
        (data) => data.totalOverTimeMinutes > 0,
      );
    } else if (
      value?.includes(capitalizeUnderscore(SelectorFilterTypes.NO_PAY))
    ) {
      filteredData = filteredData?.filter((data) => data.totalNoPayMinutes > 0);
    }

    setDataSource(filteredData);
  };

  const handleEmployees = (value: number[]) => {
    setEmployees(value);
    let filteredData = attendanceData?.data;
    if (value.length > 0) {
      filteredData = filteredData?.filter((data) => value.includes(data.id));
    }
    setDataSource(filteredData);
  };

  useEffect(() => {
    const employee = employees.map((item) => {
      return { id: item };
    });
    const data = attendanceData?.data.filter(({ id: i }) =>
      employee.some(({ id: y }) => y === i),
    );
    const empsWithErrors = attendanceData?.data.filter((val) => !val.id);
    empsWithErrors?.forEach((empWithError) => data?.push(empWithError));
    setDataSource(data);
  }, [attendanceData, employees]);

  const threeDotMenu = () => (
    <MenuStyle>
      <MenuItem onClick={() => navigate('/time-attendance/log-trail')}>
        View LogTrail
      </MenuItem>
      <MenuItem>
        <AttendanceExport dataSource={dataSource} />
      </MenuItem>
    </MenuStyle>
  );

  return (
    <>
      {showErrorMessage && (
        <>
          <AlertMessage
            title={errorMessage}
            type="error"
            onClose={() => setShowErrorMessage(false)}
          />
        </>
      )}
      <HeaderSection>
        <LeftSection>
          <Header>
            <H type="h6">Time Attendance</H>
          </Header>
          <Div m="auto 0">
            <B type="b-large-semibold" display="flex">
              {!isLoadingAttendanceList ? (
                `${monthToString(attendanceData?.month)} / ${
                  attendanceData?.year
                }`
              ) : (
                <Spin />
              )}
            </B>
          </Div>
        </LeftSection>

        <RightSection>
          <EmployeesSelector
            isActive
            employees={employees}
            onChange={(value) => {
              handleEmployees(value);
            }}
          />

          <OvertimeNoPaySelector onChange={(value) => handleFilter(value)} />
          <MobileHideButton>
            <DropdownIcon
              marginLeft={16}
              overlay={threeDotMenu}
              placement="bottomRight"
              trigger={['click']}
            />
          </MobileHideButton>
        </RightSection>
      </HeaderSection>
      <DefaultBody>
        <StyledTableDiv>
          <AttendanceListViewTbl
            loading={isLoadingAttendanceList}
            dataSource={dataSource}
            columns={columns}
            withBorders
            pagination={false}
            rowClassName={(record, index) =>
              record.hasErrors ? 'exceeded-plain-row' : ''
            }
            rowKey={(record) => record.employeeId}
            scroll={{
              y: window.innerHeight - 400,
            }}
            rowSelection={
              attendanceData?.status === 'APPROVED' ||
              attendanceData?.status === 'ARCHIVED'
                ? undefined
                : {
                    selectedRowKeys: selectedRowKeys,
                    onChange: (
                      _selectedRowKeys: React.Key[],
                      selectedRows: IUserAttendanceSummary[],
                    ) => {
                      setSelectedRowKeys(
                        selectedRows.map((item) => item.employeeId),
                      );
                    },
                    getCheckboxProps: (record: IUserAttendanceSummary) => ({
                      disabled: record.hasErrors,
                    }),
                    renderCell: (
                      _value: boolean,
                      record,
                      _index: number,
                      originNode: React.ReactNode,
                    ) => {
                      if (attendanceData?.status === 'APPROVED') {
                        return null;
                      }

                      return (
                        <div className="checkbox-cell">
                          {record.hasErrors ? (
                            <span className="error-row"></span>
                          ) : null}
                          {originNode}
                        </div>
                      );
                    },
                  }
            }
          />
        </StyledTableDiv>
      </DefaultBody>
      <FooterComponent
        leftChildren={
          <SecondaryButton
            marginLeft={23}
            onClick={() => navigate('/time-attendance')}>
            Back
          </SecondaryButton>
        }
        rightChildren={
          !(
            selectedRowKeys.length === 0 ||
            attendanceData?.status === 'APPROVED' ||
            attendanceData?.status === 'ARCHIVED'
          ) && (
            <PrimaryButton
              onClick={() => {
                setIsOpen(true);
              }}
              ml={16}
              disabled={
                selectedRowKeys.length === 0 ||
                attendanceData?.status === 'APPROVED' ||
                attendanceData?.status === 'ARCHIVED'
              }
              htmlType="submit">
              Approve
            </PrimaryButton>
          )
        }
      />
      {isOpen && (
        <ApproveModal
          loading={isLoadingUpdateStatus}
          visible={isOpen}
          onClose={() => setIsOpen(false)}
          onSubmit={handleApprove}
          data={modalData}
        />
      )}
    </>
  );
};

export default View;
