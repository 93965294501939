import {
  DataExtractorTypesEnum,
  DataImportStrategyEnum,
  DataExtractorCSVEnum,
} from '../store/actions';
import { BanksEnum, SampathBankTypeEnum } from '../types';

export const SAMPLE_CSV_FOR_PAYROLL =
  'https://corporate-static-contents.s3.ap-south-1.amazonaws.com/humanised-tech-payroll-sample-1.5.csv';

export interface BankDetailsI {
  label: string;
  value: BanksEnum;
  code: number;
  type?: any;
}

export const HNB_BANK_CONFIG: BankDetailsI = {
  label: 'Hatton National Bank PLC',
  value: BanksEnum.HNB,
  code: 7083,
};
export const SAMPATH_BANK_CONFIG: BankDetailsI = {
  label: 'Sampath Bank PLC',
  value: BanksEnum.SAMPATH,
  code: 7278,
  type: SampathBankTypeEnum,
};

export const BOC_BANK_CONFIG: BankDetailsI = {
  label: 'Bank Of Ceylon',
  value: BanksEnum.BOC,
  code: 7010,
};

export const SCB_BANK_CONFIG: BankDetailsI = {
  label: 'Standard Chartered Bank',
  value: BanksEnum.SCB,
  code: 7038,
};

export const COM_BANK_CONFIG: BankDetailsI = {
  label: 'Commercial Bank',
  value: BanksEnum.CMB,
  code: 7056,
};

export const HSBC_BANK_CONFIG: BankDetailsI = {
  label: 'HSBC Bank',
  value: BanksEnum.HSBC,
  code: 7092,
};

export const NTB_BANK_CONFIG: BankDetailsI = {
  label: 'NTB Bank',
  value: BanksEnum.NTB,
  code: 7162,
};

export const BANK_EXPORT_TYPES: BankDetailsI[] = [
  HNB_BANK_CONFIG,
  SAMPATH_BANK_CONFIG,
  BOC_BANK_CONFIG,
  SCB_BANK_CONFIG,
  COM_BANK_CONFIG,
  HSBC_BANK_CONFIG,
  NTB_BANK_CONFIG,
];

export interface DataImportConfig {
  extractorType: DataExtractorTypesEnum;
  importType: DataImportStrategyEnum;
  sampleFiles: {
    [fileName: string]: string;
  };
}

export const DEFAULT_IMPORT_CONFIG: DataImportConfig = {
  extractorType: DataExtractorTypesEnum.STANDARD_1,
  importType: DataImportStrategyEnum.EmployeeOnly,
  sampleFiles: {
    [DataExtractorCSVEnum.SALARY_CSV]:
      'https://docs.google.com/spreadsheets/d/18-Cf8tM7Bb8W8wnE1OP620UGbbdkNSC6/edit?usp=sharing&ouid=107595653366325740965&rtpof=true&sd=true',
    [DataExtractorCSVEnum.EMPLOYEE_CSV]:
      'https://docs.google.com/spreadsheets/d/18-Cf8tM7Bb8W8wnE1OP620UGbbdkNSC6/edit?usp=sharing&ouid=107595653366325740965&rtpof=true&sd=true',
  },
};

export const DATA_IMPORT_CONFIG: DataImportConfig[] = [DEFAULT_IMPORT_CONFIG];
