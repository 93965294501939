import { Moment } from 'moment';
import { LeavePolicyGroupI, LeaveSettingsI } from './leave.types';
import { ComBankTypeEnum, SampathBankTypeEnum } from './expense.types';
import { AttendanceSettings } from './attendance.types';

export interface OrganizationI {
  id: number;
  name: string;
  email: string;
  address: string;
  telephoneNumber: string;
  industry: string;
  location?: string[];
  epfZone: string;
  epf: string;
  etf: string;
  startTime: string;
  endTime: string;
  calenderType: string;
  logo: string;
  accessGroups: string[] | null;
  designations: string[] | null;
  employmentType: string[] | null;
  organizationHoliday: OrganizationHolidayI[];
  dataExtractorTypes?: string | null;
  bankDetails: OrgBankDetailsI | null;
  isUsingSystemLeaves?: boolean;
  isLeaveInPayroll?: boolean;
  isFourDecimalPoint?: boolean;
  isStampDutyAllowed?: boolean;
  paySlipCustomizations: PaySlipCustomizations;
  registrationNumber?: string;
  paySlipConfig?: string;
  seal: string;
  monthlyWorkingHours: number;
  leaveSettings: LeaveSettingsI;
  otSettings: OtSettingsI;
  otPayItemTypeSelection: otPayItemType | null;
  otTypes: OTTypesI[];
  automatedAllowanceSettings: AutomatedAllowanceSettingI;
  automatedAllowanceTypes: AutomatedAllowanceTypesI[];
  peggedSettings: OtSettingsI;
  PeggedTypeSelection: PeggedTypeSelection | null;
  payrunStartDate: number;
  taxOnTaxSettings: TaxOnTaxSettingsI;
}

export interface UpdateOrganizationI extends OrganizationI {
  leaveAllowedForPayslip: boolean;
  nicInPayslip: boolean;
  bankDetailsInPayslip: boolean;
  printedDetailsInPayslip: boolean;
  companySealInPayslip: boolean;
  companyRegistrationNumberInPayslip: boolean;
  logoInPayslip: boolean;
  leaveInfo: {
    FULL_TIME: LeaveInfoI[];
    PROBATION: LeaveInfoI[];
  };
  payItemTypeSelection: ProRataPayItemTypesI;
}
export interface LeaveInfoI {
  id: boolean;
  maxPerYear: boolean;
}

export interface ProRataPayItemTypesI {
  BASIC: boolean;
  FIXED_ALLOWANCE: boolean;
  VARIABLE_ALLOWANCE: boolean;
}

export interface OtSettingsI {
  ot: OTI;
  otEnum: [];
}

export interface OTI {
  status: boolean;
  isApit?: boolean;
  isEpf?: boolean;
}

export interface AutomatedAllowanceSettingI {
  automatedAllowance: AutomatedAllowanceI;
}

export interface AutomatedAllowanceI {
  status: boolean;
}

export interface OTTypesI {
  id: number | string;
  otName: string;
  rate: number;
  isEnable: boolean;
}

export interface AutomatedAllowanceTypesI {
  id: string;
  allowanceName: string;
  amount?: number;
  isEpf: boolean;
  isApit: boolean;
  isEnable: boolean;
}

export interface otPayItemType {
  BASIC: boolean;
  FIXED_ALLOWANCE: boolean;
  VARIABLE_ALLOWANCE: boolean;
}

export enum BasedCurrencyEnum {
  LKR = 'LKR',
  FOREIGN_CURRENCY = 'FOREIGN_CURRENCY',
}

export enum PegHandledBy {
  ADD_AS_AN_ALLOWANCE = 'Add as an allowance',
  ADD_TO_BASIC_SALARY = 'Add to Basic salary',
}
export enum CurrencyType {
  USD = 'USD',
  AUD = 'AUD',
  EURO = 'EURO',
  POUNDS = 'POUNDS',
}

export enum EmploymentType {
  EMPLOYER = 'Employer',
  EMPLOYEE = 'Employee',
}
export interface PeggedI {
  status: boolean;
}

export interface TaxOnTaxI {
  status: boolean;
}

export interface PeggedTypeSelection {
  BASIC: boolean;
  FIXED_ALLOWANCE: boolean;
  VARIABLE_ALLOWANCE: boolean;
}

export interface PeggedInfoI {
  basedCurrency: BasedCurrencyEnum;
  foreignCurrencyType: CurrencyType;
  exchangeRate: number;
  currency: CurrencyType;
  handledBy: PegHandledBy;
  allowanceTitle: string;
  isEpfEtf: boolean;
  isApit: boolean;
}

export interface TaxOnTaxInfoI {
  borneBy: string;
}
export interface PeggedSettingsI {
  pegged: PeggedI;
  peggedInfo: PeggedInfoI;
}

export enum WorkTimeUnitType {
  DAYS_HALF_DAYS = 'daysHalfDays',
  DAYS_HOURS = 'daysHours',
  DECIMAL = 'decimalInputs',
}
export interface TaxOnTaxSettingsI {
  taxOnTax: TaxOnTaxI;
  taxOnTaxInfo: TaxOnTaxInfoI;
}

export interface SystemUpdateMessagesI {
  id: number;
  title: string;
  description: string;
  from: string;
  to: string;
}

export interface ProrataTypeSelection {
  BASIC: boolean;
  FIXED_ALLOWANCE: boolean;
  VARIABLE_ALLOWANCE: boolean;
}

export interface ProrataI {
  status: boolean;
}

export interface ProrataSettingsI {
  prorata: ProrataI;
}

export interface OrganizationBodyI extends BudgetaryAllowanceSettingI {
  id: number;
  name: string;
  email: string;
  address: string;
  telephoneNumber: string;
  industry: string;
  location?: string[];
  epfZone: string;
  epf: string;
  etf: string;
  startTime: string;
  endTime: string;
  monthlyWorkingDays: number;
  monthlyWorkingDaysSettings: string;
  dailyWorkingHours: number;
  calenderType: string;
  logo: string;
  accessGroups: string[] | null;
  designations: string[] | null;
  employmentType: string[] | null;
  organizationHoliday: OrganizationHolidayI[];
  dataExtractorTypes?: string | null;
  bankDetails: OrgBankDetailsI | null;
  isUsingSystemLeaves?: boolean;
  isLeaveInPayroll?: boolean;
  isYearToDateInPayslip?: boolean;
  isStampDutyAllowed?: boolean;
  paySlipCustomizations: PaySlipCustomizations;
  registrationNumber?: string;
  paySlipConfig?: string;
  seal: string;
  leavePolicyGroup: LeavePolicyGroupI[];
  leaveSettings: LeaveSettingsI;
  payItemTypes: ProRataPayItemTypesI | null;
  otSettings: OtSettingsI;
  automatedAllowanceSettings: AutomatedAllowanceSettingI;
  otPayItemTypeSelection: otPayItemType | null;
  otTypes: OTTypesI[];
  automatedAllowanceTypes: AutomatedAllowanceTypesI[];
  peggedSettings: PeggedSettingsI;
  PeggedTypeSelection: PeggedTypeSelection | null;
  payrunStartDate: number;
  workTimeUnitType?: string;
  taxOnTaxSettings: TaxOnTaxSettingsI;
  isFourDecimalPoint: boolean;
  prorataSettings: ProrataSettingsI;
  prorataTypeSelection: ProrataTypeSelection;
  updatedAt: string;
  timeAttendanceSettings: AttendanceSettings;
}

export interface BudgetaryAllowanceSettingI {
  isAutoBudgetaryReliefAllowance: boolean;
  isEpfEtfBudgetaryAllowance: boolean;
  isApitBudgetaryAllowance: boolean;
}

export interface UpcomingNoteReturnI {
  type: UpcomingTypesEnum.Note;
  date: string;
  note: string;
  userName: string;
}

export interface BirthdayI {
  type: UpcomingTypesEnum.BirthDay;
  date: string;
  birthdayText: string;
}

export interface CelebrationReturnI {
  type: UpcomingTypesEnum.Anniversary;
  anniversaryYears: number;
  date: string;
  event: string;
}
export interface UpcomingI {
  birthdays: BirthdayI[];
  anniversaries: CelebrationReturnI[];
  notifications: UpcomingNoteReturnI[];
}

export interface OrgBankDetailsI {
  bankCode: number | null;
  bankName: string | null;
  branchCode: number | null;
  accountName: string | null;
  accountNumber: string | null;
  comBankType?: ComBankTypeEnum;
  sampathBankType?: SampathBankTypeEnum;
}
export interface NewHolidayI {
  date: string;
  description: string;
}

export interface HolidayI {
  id?: string;
  date?: string | null;
  description?: string | null;
  isHoliday: boolean | undefined;
}

export interface NewHolidayI {
  date: string;
  description: string;
}

export enum UpcomingTypesEnum {
  BirthDay = 'BirthDay',
  Anniversary = 'Anniversary',
  Note = 'Note',
  No_Any_Notifications = 'No_Any_Notifications',
}

export interface OrganizationHolidayI {
  createdAt: string | null;
  date: Moment | null;
  description: string | null;
  id: number;
  isDefault: boolean;
  isHoliday: boolean;
  note: string | null;
  organizationId: number;
  updatedAt: string | null;
  weekDay: string | null;
}

export interface PaySlipCustomizations {
  leaveAllowedForPayslip: boolean;
  nicInPayslip: boolean;
  bankDetailsInPayslip: boolean;
  printedDetailsInPayslip: boolean;
  companySealInPayslip: boolean;
  companyRegistrationNumberInPayslip: boolean;
  logoInPayslip: boolean;
  isYearToDateInPayslip: boolean;
}

export enum CustomizationEnum {
  GROUP = 'GROUP',
  DESIGNATION = 'DESIGNATION',
  EMPLOYMENT_TYPE = 'EMPLOYMENT_TYPE',
  TIME_ATTENDANCE_CSV = 'TIME_ATTENDANCE_CSV',
}

export interface OrganizationDataI extends OrganizationBodyI {
  leaveAllowedForPayslip: boolean;
  nicInPayslip: boolean;
  bankDetailsInPayslip: boolean;
  printedDetailsInPayslip: boolean;
  companySealInPayslip: boolean;
  companyRegistrationNumberInPayslip: boolean;
  logoInPayslip: boolean;
}
