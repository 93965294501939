import * as ExcelJS from 'exceljs';

export const addFullBorders = (
  workSheet: ExcelJS.Worksheet,
  cells: string[],
): void => {
  cells.forEach((cell) => {
    workSheet.getCell(cell).border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
  });
};

export const centerTexts = (
  row: ExcelJS.Row,
  cells: string[] | number[],
): void => {
  cells.forEach((cell: any) => {
    row.getCell(cell).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };
  });
};

export const currencyFormat = (
  workSheet: ExcelJS.Worksheet,
  cells: string[],
): void => {
  cells.forEach((cell) => {
    workSheet.getCell(cell).numFmt = '#,##0.00;[Red]-$#,##0.00';
  });
};

export const centerTextsByWorksheet = (
  workSheet: ExcelJS.Worksheet,
  cells: string[],
): void => {
  cells.forEach((cell) => {
    workSheet.getCell(cell).alignment = {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center',
    };
  });
};
