import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import {
  DeductionGroupI,
  PublishSummaryPayrollI,
} from '../../../store/actions';
import { PayrollItemTypeEnum, PayrunType } from '../../../types/payroll.types';

export const generateJournal = (
  payload: PublishSummaryPayrollI,
  payrunType?: string | null,
) => {
  const allowanceGroup: DeductionGroupI[] =
    payrunType === PayrunType.MID_CYCLE_PAYRUN
      ? payload.allowanceGroup.filter(
          (item) =>
            item.type !== PayrollItemTypeEnum.FIXED_ALLOWANCE &&
            item.type !== PayrollItemTypeEnum.NON_CASH_BENEFITS,
        ) || []
      : payload.allowanceGroup || [];

  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet('SalaryJournal');
  worksheet.columns = [
    { key: 'A', width: 25 },
    { key: 'B', width: 20 },
  ];

  worksheet.addRows([
    { A: 'Salary Journal' },
    [],
    [],
    { A: 'No Of Employees', B: payload.noOfEmployees },
    {
      A: 'Payroll Start Date',
      B: moment(payload.payrollStartDate).format('YYYY MMMM DD'),
    },
    {
      A: 'Payroll End Date',
      B: moment(payload.payrollEndDate).format('YYYY MMMM DD'),
    },
    { A: '-------------', B: '' },
  ]);

  for (const allowance of allowanceGroup) {
    worksheet.addRow({ A: allowance.name, B: allowance.amount });
  }

  worksheet.addRow({ A: '-------------', B: '' });

  for (const deduction of payload.deductionsGroup) {
    worksheet.addRow({ A: deduction.name, B: deduction.amount });
  }
  worksheet.addRow({ A: 'Lump Sum', B: payload.lumpSumTotal });
  payrunType != PayrunType.MID_CYCLE_PAYRUN
    ? worksheet.addRows([
        { A: '-------------', B: '' },
        { A: 'Total Basics', B: payload.basic },
        {
          A: 'Total Fixed Allowances',
          B: payload.fixedAllowance,
        },
        {
          A: 'Total Variable Allowances',
          B: payload.variableAllowance,
        },
        { A: '-------------', B: '' },
        { A: 'Employer ETF 3%', B: payload.etf },
        { A: 'Employer EPF 12%', B: payload.employerEpf },
        { A: 'APIT', B: payload.APIT },
        { A: 'Cost To Company', B: payload.costToCompany },
      ])
    : worksheet.addRows([
        { A: '-------------', B: '' },
        {
          A: 'Total Variable Allowances',
          B: payload.variableAllowance,
        },
        { A: '-------------', B: '' },
        { A: 'Employer ETF 3%', B: payload.etf },
        { A: 'Employer EPF 12%', B: payload.employerEpf },
        { A: 'Cost To Company', B: payload.costToCompany },
      ]);

  const columns = worksheet.columns;
  columns[1].alignment = {
    vertical: 'middle',
    horizontal: 'right',
  };

  workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `Salary_Journal_${moment().format('DD-MM-YYYY')}.xlsx`,
    );
  });
};
