import { useEffect, useState } from 'react';

import { clone, cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';

import { LEAVE_LIST } from '../../../configs/employeeConfigs';
import { OrganizationHolidayI } from '../../../types/organization.types';
import {
  HOURS_PER_DAY,
  LeaveBalance,
  LeaveNameEnum,
  LeaveNameI,
  LeaveNameOption,
  LeaveTypesEnum,
  LeaveViewI,
  Permission,
  TIME_FORMAT,
} from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import { isSameDay } from '../../../util/utils';
import {
  getDurationInHoursAsFloat,
  getDurationInHoursMinutes,
  getHoursDaysFromHours,
} from './dateAndTime.util';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import { disableSickleavesOrg } from '../../../configs/appConfig';

export const onTimeChange = (
  value: Moment,
  leaveDates: LeaveViewI[],
  item: LeaveViewI,
  i: number,
  type: 'from' | 'to',
) => {
  if (type == 'from') leaveDates[i].fromTime = value.format(TIME_FORMAT);
  if (type == 'to') leaveDates[i].toTime = value.format(TIME_FORMAT);
  leaveDates[i].description = getDurationInHoursMinutes(
    item?.fromTime,
    item?.toTime,
  );
  leaveDates[i].hours = getDurationInHoursAsFloat(item?.fromTime, item?.toTime);
  return cloneDeep(leaveDates);
};

export const onHoursChange = (
  value: string,
  leaveDates: LeaveViewI[],
  i: number,
) => {
  leaveDates[i].hours = parseFloat(value);
  return cloneDeep(leaveDates);
};

export const calculateLeavesBalance = (
  annualLeaves: number,
  casualLeaves: number,
  sickLeaves: number,
): number => {
  return annualLeaves + casualLeaves + sickLeaves;
};

const DEFAULT_LEAVE_LIST = {
  annualLeaves: 0,
  sickLeaves: 0,
  casualLeaves: 0,
  noPay: 0,
  studyLeave: 0,
  trainingLeave: 0,
  workOnHoliday: 0,
  maternityLeave: 0,
  lieuLeave: 0,
};
const ORGANIZATION_DURATION = {
  startTime: '08.00AM',
  endTime: '05.00PM',
};
export const useGetLeaveStatus = (leaveSummery: LeaveBalance[]) => {
  const [leaveStatus, setLeaveStatus] = useState<LeaveNameI>(
    DEFAULT_LEAVE_LIST,
  );

  useEffect(() => {
    const leaveStatusCopy = { ...DEFAULT_LEAVE_LIST };
    if (leaveSummery && leaveSummery.length) {
      leaveSummery.forEach((item) => {
        if (item.leaveName == LeaveNameEnum.ANNUAL_LEAVE) {
          leaveStatusCopy.annualLeaves = item.balance;
        } else if (item.leaveName == LeaveNameEnum.SICK_LEAVE) {
          leaveStatusCopy.sickLeaves = item.balance;
        } else if (item.leaveName == LeaveNameEnum.CASUAL_LEAVE) {
          leaveStatusCopy.casualLeaves = item.balance;
        } else if (item.leaveName == LeaveNameEnum.NO_PAY) {
          leaveStatusCopy.noPay = item.balance;
        } else if (item.leaveName == LeaveNameEnum.STUDY_LEAVE) {
          leaveStatusCopy.studyLeave = item.balance;
        } else if (item.leaveName == LeaveNameEnum.TRAINING_LEAVE) {
          leaveStatusCopy.trainingLeave = item.balance;
        } else if (item.leaveName == LeaveNameEnum.WORK_ON_HOLIDAY) {
          leaveStatusCopy.workOnHoliday = item.balance;
        } else if (item.leaveName == LeaveNameEnum.MATERNITY_LEAVE) {
          leaveStatusCopy.maternityLeave = item.balance;
        } else if (item.leaveName == LeaveNameEnum.LIEU_LEAVE) {
          leaveStatusCopy.lieuLeave = item.balance;
        }
      });
      setLeaveStatus(leaveStatusCopy);
    }
  }, [leaveSummery]);

  return leaveStatus;
};

export const getLeaveBalance = (
  type: string | null,
  leaveStatus: LeaveNameI,
) => {
  switch (type) {
    case LeaveNameEnum.ANNUAL_LEAVE:
      return `Annual Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.annualLeaves).text
      }`;
    case LeaveNameEnum.CASUAL_LEAVE:
      return `Casual Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.casualLeaves).text
      }`;
    case LeaveNameEnum.SICK_LEAVE:
      return `Sick Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.sickLeaves).text
      }`;
    case LeaveNameEnum.NO_PAY:
      return `No Pay Balance : ${
        getHoursDaysFromHours(leaveStatus.noPay).text
      }`;
    case LeaveNameEnum.STUDY_LEAVE:
      return `Study Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.studyLeave).text
      }`;
    case LeaveNameEnum.TRAINING_LEAVE:
      return `Training Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.trainingLeave).text
      }`;
    case LeaveNameEnum.WORK_ON_HOLIDAY:
      return `Work On Holiday Balance : ${
        getHoursDaysFromHours(leaveStatus.workOnHoliday).text
      }`;
    case LeaveNameEnum.MATERNITY_LEAVE:
      return `Maternity Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.maternityLeave).text
      }`;
    default:
      return null;
  }
};

export const getLeaveTypes = (
  leaveSummery: LeaveBalance[] | null,
  otherLeaveLength: number,
  noPayAllowed?: boolean,
) => {
  return LEAVE_LIST.filter((item) => {
    if (item.value === 'NO_PAY') {
      return (
        isAllowed(Permission.CREATE_EDIT_LEAVES_USER) ||
        (isManager() && noPayAllowed)
      );
    } else if (
      getCurrentAuthPayload()?.organizationId === disableSickleavesOrg &&
      item.value === LeaveNameEnum.SICK_LEAVE
    ) {
      return false;
    } else {
      return true;
    }
  }).map((leaveType) => {
    let newLeaveType: {
      label: string;
      value: string;
      disabled?: boolean;
    } = leaveType;
    const summery =
      leaveSummery &&
      leaveSummery.find(
        (summery: LeaveBalance) => leaveType.value === summery.leaveName,
      );
    if (summery) {
      newLeaveType = { ...leaveType, disabled: summery.balance <= 0 };
    }
    if (leaveType.value === LeaveTypesEnum.OTHER) {
      newLeaveType = { ...leaveType, disabled: otherLeaveLength <= 0 };
    }
    return newLeaveType;
  });
};

export const getOtherLeaveTypes = (
  leaveNameOptions: LeaveNameOption[] | undefined,
  leaveSummery: LeaveBalance[] | null,
) => {
  return leaveNameOptions
    ? leaveNameOptions.map((leaveName) => {
        const summery = leaveSummery?.find(
          (summery) => leaveName.value === summery.leaveName,
        );
        if (summery) {
          return { ...leaveName, disabled: summery.balance <= 0 };
        }
        return leaveName;
      })
    : [];
};

export const onDateRangeChanged = (
  dateRanges: string[],
  leaveDates: LeaveViewI[],
  organizationData: {
    organizationHoliday: OrganizationHolidayI[];
    startTime?: string;
    endTime?: string;
  } | null,
) => {
  const curentLeaveDates = clone(leaveDates);
  return dateRanges.map((aDay) => {
    let isHoliday = false;
    let description = '8H';

    if (curentLeaveDates.length) {
      const isExist = curentLeaveDates.find(
        (leaveDate) => leaveDate.date === aDay,
      );
      if (isExist) {
        return isExist;
      }
    }

    (organizationData?.organizationHoliday || []).forEach((holiday) => {
      if (isSameDay(moment(holiday.date), moment(aDay)) && holiday.isHoliday) {
        isHoliday = true;
        description = holiday.description || '';
      }
    });

    const dayOfWeek = moment(aDay).isoWeekday();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;
    if (isWeekend) {
      isHoliday = true;
      description = 'Weekend';
    }

    return {
      isHoliday,
      date: aDay,
      description: description,
      hours: HOURS_PER_DAY,
      fromTime: moment(
        organizationData?.startTime || ORGANIZATION_DURATION.startTime,
        ['h:mmA'],
      ).format(TIME_FORMAT),
      toTime: moment(
        organizationData?.endTime || ORGANIZATION_DURATION.endTime,
        ['h:mmA'],
      ).format(TIME_FORMAT),
    };
  });
};

export function onLeaveDayClose(
  value: LeaveViewI,
  leaveDates: LeaveViewI[],
  setStartValue: (value: React.SetStateAction<string | undefined>) => void,
  setEndValue: (value: React.SetStateAction<string | undefined>) => void,
  setLeaveDates: (value: React.SetStateAction<LeaveViewI[]>) => void,
) {
  if (leaveDates.length > 1) {
    //change form from date if the first date is removed
    if (leaveDates[0].date === value.date) {
      setStartValue(leaveDates[1].date);
    }
    //change form To date if the last date is removed
    if (leaveDates[leaveDates.length - 1].date === value.date) {
      setEndValue(leaveDates[leaveDates.length - 2].date);
    }
    setLeaveDates(
      cloneDeep(leaveDates.filter((item) => item.date !== value.date)),
    );
  }
}
